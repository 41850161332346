var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap", attrs: { id: "result" } }, [
    _c(
      "div",
      { staticClass: "stepper_container" },
      [_c("Stepper", { attrs: { step: 3, status: _vm.status === "已付款" } })],
      1
    ),
    _vm.status && _vm.status === "已付款"
      ? _c("div", { staticClass: "resultBox" }, [
          _c("h1", [_vm._v("購買成功！")]),
          _vm.order.ReceiverMemberId
            ? _c("div", { staticClass: "resultBox_desc" }, [
                _vm._v(
                  "\n      已將" + _vm._s(_vm.order.Appliance) + "送給\n      "
                ),
                _c("br", { staticClass: "hidden-mobile-up" }),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.order.ReceiverName) +
                    " (ID:" +
                    _vm._s(_vm.order.ReceiverMemberId) +
                    ") 囉\n    "
                )
              ])
            : _vm._e(),
          _c("img", {
            attrs: { src: require("@/assets/personal.svg"), alt: "" }
          }),
          _vm.order.ReceiverMemberId
            ? _c(
                "div",
                {
                  staticClass: "toUnlockPage_btn",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/")
                    }
                  }
                },
                [_vm._v("\n      返回首頁\n    ")]
              )
            : _c(
                "div",
                {
                  staticClass: "toUnlockPage_btn",
                  on: {
                    click: function($event) {
                      return _vm.toUnlockPage()
                    }
                  }
                },
                [_vm._v("\n      觀看解鎖內容\n    ")]
              ),
          _c(
            "div",
            {
              staticClass: "toRecordPage_btn",
              on: {
                click: function($event) {
                  return _vm.toRecord()
                }
              }
            },
            [_vm._v("檢視購買紀錄")]
          )
        ])
      : _vm._e(),
    _vm.status && _vm.status !== "已付款"
      ? _c("div", { staticClass: "resultBox" }, [
          _c("h1", [_vm._v(_vm._s(_vm.errorText || "信用卡刷卡失敗！"))]),
          _c("img", {
            attrs: {
              src: require("../../assets/訂單流程/failResult.svg"),
              alt: ""
            }
          }),
          _c(
            "div",
            {
              staticClass: "toUnlockPage_btn",
              on: {
                click: function($event) {
                  return _vm.buyAgain()
                }
              }
            },
            [_vm._v("重新購買")]
          )
        ])
      : _vm._e(),
    !_vm.status
      ? _c("div", { staticClass: "resultBox" }, [
          _c("h1", [_vm._v("請稍候...")])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="wrap" id="result">
    <div class="stepper_container">
      <Stepper :step="3" :status="status === '已付款'" />
    </div>
    <!-- //成功 -->
    <div class="resultBox" v-if="status && status === '已付款'">
      <h1>購買成功！</h1>
      <div class="resultBox_desc" v-if="order.ReceiverMemberId">
        已將{{ order.Appliance }}送給
        <br class="hidden-mobile-up" />
        {{ order.ReceiverName }} (ID:{{ order.ReceiverMemberId }}) 囉
      </div>
      <img src="@/assets/personal.svg" alt="" />
      <div
        class="toUnlockPage_btn"
        @click="$router.push('/')"
        v-if="order.ReceiverMemberId"
      >
        返回首頁
      </div>
      <div class="toUnlockPage_btn" @click="toUnlockPage()" v-else>
        觀看解鎖內容
      </div>
      <div class="toRecordPage_btn" @click="toRecord()">檢視購買紀錄</div>
    </div>
    <!-- //失敗 -->
    <div class="resultBox" v-if="status && status !== '已付款'">
      <h1>{{ errorText || "信用卡刷卡失敗！" }}</h1>
      <img src="../../assets/訂單流程/failResult.svg" alt="" />
      <div class="toUnlockPage_btn" @click="buyAgain()">重新購買</div>
    </div>
    <div class="resultBox" v-if="!status">
      <h1>請稍候...</h1>
    </div>
  </div>
</template>

<script>
import Stepper from "../../components/訂單流程/Stepper";
export default {
  components: { Stepper },
  data() {
    return {
      order: null,
      status: null,
      errorText: "",
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let id = this.$route.params.id;
      this.$store.commit("setLoading", true);
      setTimeout(() => {
        this.$API
          .Get_PurchaseRecord(id)
          .then((res) => {
            this.order = res.data.Data;
            this.status = res.data.Data.PaymentInfo.Status;
            this.errorText = res.data.Data.PaymentInfo.Status;
          })
          .catch(() => {
            alert("不好意思，訂單不存在!");
            this.$store.commit("setLoading", false);
            this.$router.replace("/");
          });
      }, 2000);
    },
    toUnlockPage() {
      //先將好有資訊帶入nowUserData
      switch (this.order.ProductCode) {
        case "HouseInterpretation":
          this.$API
            .Get_AstrolabeFile(this.order.AstrolabeFriendInfos[0].AstrolabeFileId)
            .then((data) => {
              this.$store.commit("set_nowUserData", data.Data);
              return this.$router.push("/astrolabe");
            });
          break;
        case "TransitChart":
          return this.$router.push("/timelineAstrolabe");
        case "MoonNote":
          return this.$router.push("/moonday-list");
        case "ProductVoucher_Book2024":
          return this.$router.push(`/member/detail/${this.$route.params.id}`);
        default:
          return this.$router.push("/book2024/my");
      }
    },
    toRecord() {
      this.$router.push("/member/record");
    },
    buyAgain() {
      this.$router.push("/store");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
#result {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .resultBox_desc {
    font-size: 24px;
    color: #868686;
    margin-top: 20px;
    @include mobile {
      margin-top: 12px;
      font-size: 20px;
    }
  }
  .stepper_container {
    width: 566px;
    margin-top: 0;
    margin-bottom: 73px;
  }
  .resultBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    h1 {
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      color: $theme-color-2;
      @include mobile {
        font-size: 32px;
        line-height: 46px;
      }
    }
    img {
      width: 300px;
      margin-top: 60px;
      margin-bottom: 60px;
      @include mobile {
        width: 200px;
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
    .toUnlockPage_btn {
      @include btn-default(375px, 64px);
      @include mobile {
        @include btn-default(232px, 56px);
      }
    }
    .toRecordPage_btn {
      margin-top: 20px;
      font-weight: 700;
      font-size: 18px;
      line-height: 36px;
      color: #9b9b9b;
      cursor: pointer;
      @include mobile {
        font-size: 18px;
        line-height: 36px;
      }
    }
  }
}
</style>

<template>
  <div v-if="afterApi">
    <div class="page-header custom-container hidden-pad-down">
      <img class="img-title" src="@/assets/header_member.svg" alt />
      <span>平台可歸戶帳號</span>
    </div>
    <div class="importList">
      <div class="importList__box">
        <div class="importList__photo">
          <div class="photo__inset">
            <div class="photo__bg">
              <v-avatar size="168" class="photo">
                <img :src="headImage" />
              </v-avatar>
            </div>
          </div>
          <div class="importList__photo__platform">
            <img
              v-show="loginMethods == 'Line'"
              src="@/assets/歸戶平台/LINE.png"
              alt
            />
            <img
              v-show="loginMethods === 'Facebook'"
              src="@/assets/歸戶平台/facebook.png"
              alt
            />
            <img
              v-show="loginMethods === 'Google'"
              src="@/assets/歸戶平台/check.svg"
              alt
            />
          </div>
        </div>
        <div class="importList__id" v-show="userData.Account">您的帳號 ID</div>
        <div class="importList__account" v-show="userData.Account">
          {{ userData.Account }}
        </div>
        <div class="importList__ListTitle">
          <span>平台可註冊帳號</span>
        </div>
        <div class="importList__list">
          <!-- start -->
          <div class="importCard">
            <div class="pic_box">
              <img src="@/assets/歸戶平台/LINE.png" alt="" />
            </div>
            <div class="info_area">
              <div class="main_group">
                <div class="base">
                  <div class="name">Line</div>
                  <!-- <div
                    class="unbind"
                    v-if="
                      userData.Platforms && userData.Platforms.includes('Line')
                    "
                    @click="unbindHandler('Line')"
                  >
                    解除綁定
                  </div> -->
                </div>
                <div class="control">
                  <div
                    class="control__importfinish"
                    v-if="
                      userData.Platforms && userData.Platforms.includes('Line')
                    "
                  >
                    <img src="@/assets/歸戶平台/check.svg" alt="" />
                    已完成歸戶
                  </div>
                  <div
                    class="control__importBtn"
                    v-else
                    @click="toImport('Line')"
                  >
                    立即歸戶
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end -->
          <!-- start -->
          <div class="importCard">
            <div class="pic_box">
              <img src="@/assets/歸戶平台/facebook.png" alt="" />
            </div>
            <div class="info_area">
              <div class="main_group">
                <div class="base">
                  <div class="name">Facebook</div>
                  <!-- <div
                    class="unbind"
                    v-if="
                      userData.Platforms &&
                      userData.Platforms.includes('Facebook')
                    "
                    @click="unbindHandler('Facebook')"
                  >
                    解除綁定
                  </div> -->
                </div>
                <div class="control">
                  <div
                    class="control__importfinish"
                    v-if="
                      userData.Platforms &&
                      userData.Platforms.includes('Facebook')
                    "
                  >
                    <img src="@/assets/歸戶平台/check.svg" alt="" />
                    已完成歸戶
                  </div>
                  <div
                    class="control__importBtn"
                    v-else
                    @click="toImport('Facebook')"
                  >
                    立即歸戶
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end -->
        <!-- start -->
        <div class="importCard">
          <div class="pic_box">
            <img src="@/assets/歸戶平台/GOOGLE.png" alt="" />
          </div>
          <div class="info_area">
            <div class="main_group">
              <div class="base">
                <div class="name">Google</div>
                <!-- <div
                  class="unbind"
                  v-if="
                    userData.Platforms && userData.Platforms.includes('Google')
                  "
                  @click="unbindHandler('Google')"
                >
                  解除綁定
                </div> -->
              </div>
              <div class="control">
                <div
                  class="control__importfinish"
                  v-if="
                    userData.Platforms && userData.Platforms.includes('Google')
                  "
                >
                  <img src="@/assets/歸戶平台/check.svg" alt="" />
                  已完成歸戶
                </div>
                <div
                  class="control__importBtn"
                  v-else
                  @click="toImport('Google')"
                >
                  立即歸戶
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myData: null,
      afterApi: false,
      showEdit: false,
      email: "",
    };
  },
  async mounted() {
    if (window.localStorage.getItem("login_status")) {
      this.$API.Get_MemberInfo().then(async (data) => {
        let self = this.$store.getters.SELF_OBJ;
        const res = await this.$API.Get_AstrolabeFile(self.AstrolabeFileId);
        this.myData = res.Data;

        //6.5新增前端狀態keep效果
        this.$store.commit("updateMyData", this.myData);
        this.afterApi = true;
      });
    }
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    headImage() {
      return localStorage.imageUrl || this.myData.ProfilePhotoSrc;
    },
    loginMethods() {
      return localStorage.loginMethods;
    },
  },
  methods: {
    toImport(platforms) {
      this.$messagePopup(
        `先登出<br>再進行帳號歸戶`,
        "",
        "登出",
        () => {
          window.localStorage.removeItem("login_status");
          window.localStorage.removeItem("AC");
          window.localStorage.removeItem("imgUrl");
          window.localStorage.removeItem("haveNoData");
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("LT");
          window.location =
            this.$store.state.app_return_url + `?login=${platforms}`;
        },
        true
      );
    },
    unbindHandler(platforms) {
      this.$messagePopup(
        `想解除帳號歸戶嗎？`,
        "一但解除綁定後，您仍將可以根據自己的需求，來申請重新「帳號歸戶」。",
        "解除綁定",
        () => {
          //打API
          //重整
        },
        true
      );
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_mixin.scss";
@import "@/scss/header.scss";
@import "@/scss/toast.scss";

.importList {
  margin-top: 50px;
  width: 100%;
  height: auto;
  box-shadow: -4px 0 12px #ffffff, -2px 0 8px #ffffff,
    4px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: $theme-color-3;
  border-radius: 150px 150px 0 0;
  padding-bottom: 44px;
  @include align(center, flex-start);
  @include pad {
    border-radius: 32px 32px 0 0;
    margin-top: 95px;
  }

  .importList__box {
    position: relative;
    @include align(flex-start, center);
    flex-direction: column;
    position: relative;
    margin-top: -104px;
    @include mobile {
      margin-top: -60px;
    }
    @include laptop {
      width: 100%;
    }
    .importList__photo {
      position: relative;
      @include rect(192px, 192px, $theme-color-3, 50%);
      @include center;
      @include mobile {
        width: 120px;
        height: 120px;
      }
      &__platform {
        position: absolute;
        right: 5px;
        bottom: 8px;
        img {
          width: 45px;
          height: 45px;
          @include mobile {
            width: 28px;
            height: 28px;
          }
        }
      }
      .photo__inset {
        @include center;
        @include rect-inset(170px, 170px, $theme-color-3, 50%);
        @include mobile {
          width: 95px;
          height: 95px;
        }
        .photo__bg {
          @include center;
          @include rect(152px, 152px, $theme-color-3, 50%);
          @include mobile {
            width: 80px;
            height: 80px;
            .photo {
              width: 105px !important;
              height: 105px !important;
            }
          }
        }
      }
    }

    .importList__id {
      margin: 36px 0 20px;
      @include mobile {
        margin: 24px 0 12px;
        font-size: 12px;
        line-height: 14px;
      }
    }
    .importList__account {
      min-width: 234px;
      padding: 6px 28px;
      margin: 0 auto;
      @include rect(auto, auto, $theme-color-3, 20px);
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: $theme-color-1;
      word-break: break-word;
      box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
      @include mobile {
        min-width: 166px;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .importList__ListTitle {
      margin-top: 56px;
      font-size: 24px;
      line-height: 24px;
      color: #ab956c;
      display: flex;
      align-items: center;
      @include mobile {
        margin-top: 38px;
        font-size: 16px;
        line-height: 24px;
      }
      span {
        display: inline-block;
        margin: 0 24px;
        @include pad {
          margin: 0 20px;
        }
      }
      &:after,
      &::before {
        content: "";
        width: 68px;
        height: 1px;
        background-color: #ab956c;
        @include pad {
          margin: 0 20px;
        }
      }
    }
    .importList__list {
      margin-top: 40px;
      @include pad {
        margin-top: 32px;
      }
    }
  }
}
.importCard {
  margin-bottom: 44px;
  width: 500px;
  height: auto;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  border-radius: 25px 50px;
  padding: 25px;
  display: flex;
  position: relative;
  transition: 0.2s;
  user-select: none;
  @include mobile {
    margin-bottom: 36px;
    width: 329px;
    height: 76px;
    padding: 14px 16px;
    border-radius: 25px 35px;
    box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
      4px 4px 10px rgba(0, 0, 0, 0.2);
  }
  .pic_box {
    width: 80px;
    height: 80px;
    background: #f0f2f4;
    box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
      2px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    margin-right: 40px;
    flex-shrink: 0;
    @include mobile {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 16px;
      top: -8px;
    }
    img {
      width: 80px;
      margin: auto;
      @include mobile {
        width: 60px;
      }
    }
  }
  .info_area {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #9b9b9b;
    @include mobile {
      margin-left: 75px;
    }
    .main_group {
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      .base {
        .name {
          color: #767676;
          font-size: 20px;
          line-height: 36px;
          font-weight: 700;
          margin-bottom: 8px;
          @include mobile {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 1px;
          }
        }
      }
      .control {
        &__importBtn {
          @include rect(120px, 42px, #798da5, 100px);
          @include center;
          color: #fff;
          cursor: pointer;
          @include pad {
            @include rect(90px, 30px, #798da5, 20px);
          }
        }
        &__importfinish {
          font-size: 16px;
          line-height: 26px;
          color: #ab956c;
          @include mobile {
            font-size: 12px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>
